import {call} from "./apiCall";

/**
 * Generate a base64 image for a given tag, and print it
 * @param {int} tagCode 
 * @param {string} base64_png 
 */
export const silentPrintTag = async (
  tagCode,
  base64_png,
  printType
) => {
    let response = await call(
        `/tags/silent-print/${tagCode}`,
        'POST',
        `data=${base64_png}&print_type=${printType}`
    )
  
  return response;
};