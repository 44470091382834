import React, {useState, useEffect} from "react";
import Card from "./components/card";
import Button from "./components/button";

import {call} from "../../../api/apiCall";
import {silentPrintTag} from "../../../api/print";
import {markTag} from "../../../api/tag";

const ModuleTagsPrinter = ({data}) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getBaseData = async () => {
      try {
        const tagsToPrint = await call(data.url, "GET");

        setTags(tagsToPrint);
      } catch (e) {
        console.log("Erreur lors de la récupération des tags: ", e);
      }
    };

    if (data) {
      getBaseData();
    }
  }, [data]);

  const handleTagLoaded = (tag) => {
    document.dispatchEvent(new CustomEvent("onPoppinContentChange"));
  }

  const List = ({tags}) => {
    if (tags.length) {
      return (
        <div className="print-container">
          {tags.map(tag => (
            <div key={tag.id}>
              <Card tag={tag} handleTagLoaded={(tag) => handleTagLoaded(tag)} />
              <Button printAction={() => printAll([tag])} label={"Imprimer l'étiquette"}  silentMode={true} tagId={tag.id} />
              <Button printAction={() => classicPrint(tag)} label={"Imprimer l'étiquette"} silentMode={false} tagId={tag.id} />
            </div>
          ))}
        </div>
      );
    }

    return false;
  };

  const classicPrint = (tag) => {
    // Get tag iFrame
    markTag(tag.id);
    window.frames[`tag-print-${tag.id}`].focus();
    window.frames[`tag-print-${tag.id}`].print();
  }

  const print = (tag) => (
    new Promise((resolve, reject) => {
      window.setTimeout(async () => {
        try {
          // Get tag iFrame
          let iframe = window.frames[`tag-print-${tag.id}`];

          // Generate image base64 from it
          let canvas = await iframe.getBase64ImageReact();
          let base64_png = canvas.toDataURL("image/png");

          // Silent print the tag
          let res = await silentPrintTag(tag.code, base64_png, tag.printType);
          let savePngSuccess = res.success;
          let printCode = res.data.code;

          if (!savePngSuccess) {
            throw ("save error", printCode);
          }

          await markTag(tag.id);

          resolve()
        } catch (e) {
          reject()
        }
      }, 1000)
    })
  )

  const printAll = async tags => {
    try {
      for (let tag of tags) {
        await print(tag);
      }
    } catch (e) {
      console.log("Erreur lors de l'impression multiple", e);
    }
  };

  return (
    <div>
      <div className={"print-container"}>
        <Button printAction={() => printAll(tags)} label={"Tout imprimer"} silentMode={true} />
      </div>
      <List tags={tags} />
      <div className={"print-container"}>
        <Button printAction={() => printAll(tags)} label={"Tout imprimer"} silentMode={true} />
      </div>
    </div>
  );
};

export default props => <ModuleTagsPrinter {...props} />;
